import React from "react";
import classnames from 'classnames';
import BottomNavigation from "@material-ui/core/BottomNavigation";

import { makeStyles } from "@material-ui/core/styles";
import MyBottomNavigationAction from "../../UI/MyBottomNavigationAction/MyBottomNavigationAction";
import home_active from "../../../assets/icons/footer/home_active.svg";
import more_active from "../../../assets/icons/footer/more_active.svg";
import offers_active from "../../../assets/icons/footer/offers_active.svg";
import redeem_active from "../../../assets/icons/footer/redeem_active.svg";
import transactions_active from "../../../assets/icons/footer/transactions_active.svg";
import { connect } from "react-redux";
import {
  changeActiveTab,
  logoutUser
} from "../../../App/redux/action";

const useStyles = makeStyles((theme) => {
  return {
    bottomNav: {
      width: "100%",
      padding: 0,
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      bottom: 0,
      height: "86px",
      boxShadow: '0px -1px 3px 0 rgba(0, 0, 0, 0.3)',
      [theme.breakpoints.down("641")]: {
        height: "82px",
      },
      [theme.breakpoints.down("481")]: {
        height: "64px",
      },
      [theme.breakpoints.down("421")]: {
        height: "54px",
      },
      [theme.breakpoints.down("381")]: {
        height: "50px",
      },
      [theme.breakpoints.down("361")]: {
        height: "48px",
      },
      [theme.breakpoints.down("321")]: {
        height: "43px",
      },
    },
    icon: {
      width: "36px",
	  height: "36px",
	  display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectFit: "contain",
      objectPosition: "center",
      marginBottom: "11px",
      [theme.breakpoints.down("481")]: {
        width: "25px",
        height: "25px",
        marginBottom: "6px",
      },
      [theme.breakpoints.down("381")]: {
        width: "18px",
        height: "18px",
      },
      [theme.breakpoints.down("321")]: {
        width: "16px",
        height: "16px",
      },
    },
    moreIcon: {
      margin: "15px 0px 26px 0px",
      width: "36px",
      height: "6px",
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.down("481")]: {
        width: "25px",
        height: "5px",
        margin: "10px 0px 16px 0px",
      },
      [theme.breakpoints.down("381")]: {
        width: "16px",
        height: "5px",
        margin: "7px 0px 13px 0px",
      },
      [theme.breakpoints.down("321")]: {
        width: "16px",
        height: "3px",
        margin: "6px 0px 12px 0px",
      },
    },
    transactionsIcon: {
      width: "30px",
      height: "38px",
      objectFit: "contain",
      objectPosition: "center",
      marginBottom: "11px",
      [theme.breakpoints.down("481")]: {
        width: "20px",
        height: "26px",
        marginBottom: "6px",
      },
      [theme.breakpoints.down("381")]: {
        width: "16px",
        height: "19px",
      },
      [theme.breakpoints.down("321")]: {
        width: "14px",
        height: "17px",
      },
    },
    home_active: {
      backgroundColor: theme.palette.footer.selectedIcon,
      " -webkit-mask-image": `url(..${home_active})`,
      "mask-image": `url(..${home_active})`,
      mask: `url(..${home_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${home_active}) no-repeat center / contain`,
    },
    home_deactive: {
      backgroundColor: theme.palette.footer.default,
      " -webkit-mask-image": `url(..${home_active})`,
      "mask-image": `url(..${home_active})`,
      mask: `url(..${home_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${home_active}) no-repeat center / contain`,
    },
    redeem_active: {
      backgroundColor: theme.palette.footer.selectedIcon,
      " -webkit-mask-image": `url(..${redeem_active})`,
      "mask-image": `url(..${redeem_active})`,
      mask: `url(..${redeem_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${redeem_active}) no-repeat center / contain`,
    },
    redeem_deactive: {
      backgroundColor: theme.palette.footer.default,
      " -webkit-mask-image": `url(..${redeem_active})`,
      "mask-image": `url(..${redeem_active})`,
      mask: `url(..${redeem_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${redeem_active}) no-repeat center / contain`,
    },
    offers_active: {
      backgroundColor: theme.palette.footer.selectedIcon,
      " -webkit-mask-image": `url(..${offers_active})`,
      "mask-image": `url(..${offers_active})`,
      mask: `url(..${offers_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${offers_active}) no-repeat center / contain`,
    },
    offers_deactive: {
      backgroundColor: theme.palette.footer.default,
      " -webkit-mask-image": `url(..${offers_active})`,
      "mask-image": `url(..${offers_active})`,
      mask: `url(..${offers_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${offers_active}) no-repeat center / contain`,
    },
    more_active: {
      backgroundColor: theme.palette.footer.selectedIcon,
      " -webkit-mask-image": `url(..${more_active})`,
      "mask-image": `url(..${more_active})`,
      mask: `url(..${more_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${more_active}) no-repeat center / contain`,
    },
    more_deactive: {
      backgroundColor: theme.palette.footer.default,
      " -webkit-mask-image": `url(..${more_active})`,
      "mask-image": `url(..${more_active})`,
      mask: `url(..${more_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${more_active}) no-repeat center / contain`,
    },
    transactions_active: {
      backgroundColor: theme.palette.footer.selectedIcon,
      " -webkit-mask-image": `url(..${transactions_active})`,
      "mask-image": `url(..${transactions_active})`,
      mask: `url(..${transactions_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${transactions_active}) no-repeat center / contain`,
    },
    transactions_deactive: {
      backgroundColor: theme.palette.footer.default,
      " -webkit-mask-image": `url(..${transactions_active})`,
      "mask-image": `url(..${transactions_active})`,
      mask: `url(..${transactions_active}) no-repeat center / contain`,
      "-webkit-mask": `url(..${transactions_active}) no-repeat center / contain`,
    },
  };
});

const Footer = (props) => {
  const classes = useStyles();

  const routes = [
    {
      name: "Home",
      link: "/",
      value: 0,
      iconPath: classes.home_deactive,
      iconActive: classes.home_active,
    },
    {
      name: "Perks",
      link: "/offers",
      value: 1,
      iconPath: classes.offers_deactive,
      iconActive: classes.offers_active,
    },
    {
      name: "Redeem",
      link: "/redeem",
      value: 2,
      iconPath: classes.redeem_deactive,
      iconActive: classes.redeem_active,
    },
    {
      name: "Transactions",
      link: "/transactions",
      value: 3,
      iconPath: classes.transactions_deactive,
      iconActive: classes.transactions_active,
    },
    {
      name: "More",
      link: "/more",
      value: 4,
      iconPath: classes.more_deactive,
      iconActive: classes.more_active,
    },
  ];

  const changeHandler = (index) => {
    setActiveTab(index);
  };

  const setActiveTab = () => {
    const currentUrl = window.location.pathname;
    const { activeTab, changeActiveTab } = props;
    if (currentUrl.includes("offers")) {
      if (activeTab !== 1) {
        changeActiveTab(1);
      }
    } else if (
      currentUrl.includes("redeem") ||
      currentUrl.includes("order-summary")
    ) {
      if (activeTab !== 2) {
        changeActiveTab(2);
      }
    } else if (currentUrl.includes("transactions")) {
      if (activeTab !== 3) {
        changeActiveTab(3);
      }
    } else if (currentUrl.includes("more")) {
      if (activeTab !== 4) {
        changeActiveTab(4);
      }
    } else {
      if (activeTab !== 0) {
        changeActiveTab(0);
      }
    }
  };

  return (
    <BottomNavigation
      value={props.activeTab}
      showLabels
      className={classes.bottomNav}
    >
      {routes.map((route) => {
        return (
          <MyBottomNavigationAction
            key={route.value}
            value={route.value}
            to={route.link}
            label={route.name}
            logoutUser={props.logoutUser}
            icon={
              <span
                className={classnames({
                  [route.iconActive]: route.value === props.activeTab,
                  [route.iconPath]: route.value !== props.activeTab,
                  [classes.moreIcon]: route.value === 4,
                  [classes.transactionsIcon]: route.value === 3,
                  [classes.icon]: true,
                })}
              />
            }
            activeTabValue={props.activeTab}
            handleChange={changeHandler}
          />
        );
      })}
    </BottomNavigation>
  );
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.reducerApp.activeTab
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTab: (id) => dispatch(changeActiveTab(id)),
    logoutUser: () => dispatch(logoutUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
