/*eslint eqeqeq: "off"*/
import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withRouter } from "react-router-dom";
import Carousal from "../Redeem/components/Carousal";
import Paper from "@material-ui/core/Paper";
import { apiData } from "../../../common/common-types";
import { getStorageItem } from "../../../common/localStorage";
import { openAlertBox } from "../../../common/AlertMessage/AlertMessage";
import down_arrow from "../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import default_product from "../../../assets/images/product/default_product.svg";
import { styles } from "./styles";
import { Checkbox, Dialog } from "@material-ui/core";
import { text } from "@material-ui/core";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
const moment = require("moment");

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      selectedVoucherValue:
        this.roundOff(
          this.props.history.location.state &&
          this.props.history.location.state.details.voucher_value
        ) || 0,
      selectedLocation: "",
      selectedQuantity: null,
      cart: [],
      imagesArray: [],
      maxQuantity: 0,
      currentQuantity: 0,
      currencyCode: "₹",
      pointsDynamicName: "",
      selectedLocation_id: null,
      checked: true,
    };
  }

  handleChange = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  quantityOptions = () => {
    const { selectedProductDetails, classes, productDetails } = this.props;

    // ------------------------old code------------

    // const { selectedProductDetails, classes, productDetails } = this.props;
    // const minQuantity =
    //   selectedProductDetails &&
    //   selectedProductDetails[0] &&
    //   selectedProductDetails[0].min_quantity;
    // const maxQty =
    //   selectedProductDetails &&
    //   selectedProductDetails[0] &&
    //   selectedProductDetails[0].max_quantity;

    // ------------------------comment part in old code------------
    // const stockQty =
    //   productDetails &&
    //   productDetails.locationWiseQuantity &&
    //   productDetails.locationWiseQuantity.length &&
    //   productDetails.locationWiseQuantity.map((x) => {
    //     if (
    //       selectedProductDetails &&
    //       selectedProductDetails[0] &&
    //       selectedProductDetails[0].id === x.products_merchant_id
    //     ) {
    //       return parseInt(x.current_quantity);
    //     }
    //   });
    // --------------------comment part in old code----------------

    // var arr = [];
    // if (productDetails !== null) {
    //   for (let i = minQuantity; i <= maxQty; i++) {
    //     arr.push(
    //       <option className={classes.optionSelect} key={i} value={i}>
    //         {i}
    //       </option>
    //     );
    //   }
    //   return arr;
    // }
    // ------------------old code------------------------


    
    // const locationName =
    //   selectedProductDetails &&
    //   selectedProductDetails[0] &&
    //   selectedProductDetails[0].location_name;

    // const locationName= this.props.location.state.details.locations&&
    // this.props.location.state.details.locations;
    const locatWiseQty = productDetails && productDetails.locationWiseQuantity;
    var crntQTY = "";
    var productsMerchantId = "";
    var minQTY = "";
    var maxQTY = "";
    var qtyValue = "";

    var loc = this.state.selectedLocation;
    let dataArray =
      locatWiseQty == undefined
        ? ""
        : locatWiseQty.map((item) => {
          if (item.location_name == loc) {
            crntQTY = parseInt(item.current_quantity);
            productsMerchantId = item.products_merchant_id;
          }
        });
    productDetails &&
      productDetails.productDetails.map((item) => {
        if (productsMerchantId == item.id) {
          minQTY = parseInt(item.min_quantity);
          maxQTY = parseInt(item.max_quantity);
        }
      });

    if (crntQTY >= maxQTY) {
      qtyValue = maxQTY;
    } else {
      qtyValue = crntQTY;
    }

    const minQuantity = minQTY;
    const maxQty = maxQTY;

    var arr = [];
    if (productDetails !== null) {
      for (let i = minQTY; i <= qtyValue; i++) {
        arr.push(
          <option className={classes.optionSelect} key={i} value={i}>
            {i}
          </option>
        );
      }
      return arr;
    }
  };

  convertDate = (expiryDate) => {
    if (expiryDate) {
      const today = moment(expiryDate);
      return today.format("Do MMM'YY");
    }
  };

  getCalculated_points = (spclPrice, sellingPrice, qty, expirDate) => {
    if (spclPrice > 0) {
      if (this.expiryDate(expirDate) > 0) {
        return this.roundOff(spclPrice * qty);
      }
    } else {
      return this.roundOff(sellingPrice * qty);
    }
  };

  getItemPrice = (specialPrice, startDate, endDate, sellingPrice) => {
    const currentDate = moment().format("YYYY-MM-DD");
    if (specialPrice === "0.0000") {
      return parseFloat(sellingPrice) % 1 !== 0
        ? parseFloat(sellingPrice).toFixed(2)
        : Math.floor(sellingPrice);
    } else if (endDate && endDate === currentDate) {
      return parseFloat(specialPrice) % 1 !== 0
        ? parseFloat(specialPrice).toFixed(2)
        : Math.floor(specialPrice);
    } else if (startDate && startDate === currentDate) {
      return parseFloat(specialPrice) % 1 !== 0
        ? parseFloat(specialPrice).toFixed(2)
        : Math.floor(specialPrice);
    } else if (moment(currentDate).isBetween(startDate, endDate)) {
      return parseFloat(specialPrice) % 1 !== 0
        ? parseFloat(specialPrice).toFixed(2)
        : Math.floor(specialPrice);
    } else {
      return parseFloat(sellingPrice) % 1 !== 0
        ? parseFloat(sellingPrice).toFixed(2)
        : Math.floor(sellingPrice);
    }
  };
  addProductToCart = async (obj) => {
    const { showLoader, productDetails } = this.props;
    showLoader(true);
    const { key, selected } = obj;
    const { selectedQuantity, selectedLocation, selectedLocation_id } =
      this.state;
    let finalCartArray = [...this.props.finalCartArray];
    const __index = finalCartArray.findIndex(
      (x) => x.product_merchant_id == selected.id
    );

    if (__index === undefined || __index === -1) {
      finalCartArray.push({
        product_merchant_id: selected.id,
        qty: selectedQuantity ? parseInt(selectedQuantity) : 1,
        location_id: selectedLocation_id
          ? selectedLocation_id
          : productDetails &&
          productDetails.locationWiseQuantity[0] &&
          productDetails.locationWiseQuantity[0].location_id,
        location_name: selectedLocation
          ? selectedLocation
          : productDetails &&
          productDetails.locationWiseQuantity[0] &&
          productDetails.locationWiseQuantity[0].location_name,
        product_id: selected.product_id,
        brand_name: selected.brand_name,
        selling_price_points_value: this.roundOff(
          selected.selling_price_points_value
        ),
        sub_product_name: selected.sub_product_name,
        short_description: selected.short_description,
        best_price: this.getItemPrice(
          selected.special_price_points_value,
          selected.special_price_start_date,
          selected.special_price_end_date,
          selected.selling_price_points_value
        ),
        voucher_value: this.roundOff(selected.voucher_value),
        special_price_points_value: this.roundOff(
          selected.special_price_points_value
        ),
        min_quantity: selected.min_quantity,
        max_quantity: selected.max_quantity,
        expiry_date: selected.expiry_date,
        image_name: selected.product_images[0],
        calculated_points: this.roundOff(
          this.getItemPrice(
            selected.special_price_points_value,
            selected.special_price_start_date,
            selected.special_price_end_date,
            selected.selling_price_points_value
          ) * (selectedQuantity ? parseInt(selectedQuantity) : 1)
        ),
      });
      this.setState({
        currentQuantity: selectedQuantity ? parseInt(selectedQuantity) : 1,
      });
      if (key === "Add") {
        openAlertBox("Product added to cart", "success");
      }
    } else {
      if (key === "Add") {
        openAlertBox("Product quantity updated to cart", "success");
      }
      finalCartArray.forEach((item) => {
        if (item.product_merchant_id === selected.id) {
          if (item.qty !== selected.max_quantity) {
            item.qty = selectedQuantity
              ? parseInt(selectedQuantity)
              : item.qty > 1
                ? item.qty
                : 1;
            item.location_id = selectedLocation_id
              ? selectedLocation_id
              : productDetails &&
              productDetails.locationWiseQuantity[0] &&
              productDetails.locationWiseQuantity[0].location_id;
            item.location_name = selectedLocation
              ? selectedLocation
              : productDetails &&
              productDetails.locationWiseQuantity[0] &&
              productDetails.locationWiseQuantity[0].location_name;
            item.calculated_points = this.roundOff(
              this.getItemPrice(
                selected.special_price_points_value,
                selected.special_price_start_date,
                selected.special_price_end_date,
                selected.selling_price_points_value
              ) * item.qty
            );
            this.setState({ currentQuantity: item.qty });
          }
        }
      });
    }

    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("merchant_id", apiData.merchant_id);
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("platform", apiData.platform);
      bodyFormData.append("cart_details", JSON.stringify(finalCartArray));
      this.props.addCart(finalCartArray);
      await this.props.addToCart(bodyFormData);

      this.cartCount();
      if (key === "buyNow") {
        this.props.history.push("/myCart");
      }
      showLoader(false);
    }
  };


  cartCount = () => {
    const cartCountFormData = new URLSearchParams();
    cartCountFormData.append(
      "customer_id",
      JSON.parse(getStorageItem("user")).id
    );
    cartCountFormData.append("merchant_id", apiData.merchant_id);
    this.props.getCartItemCount(cartCountFormData);
  };

  roundOff = (voucherValue) => {
    return parseFloat(voucherValue) % 1 !== 0
      ? parseFloat(voucherValue).toFixed(2)
      : Math.floor(voucherValue);
  };

  componentDidMount = async () => {
    const productId = window.location.pathname.split("/")[2];
    const { showLoader } = this.props;
    showLoader(true);
    // this.convertDate();
    if (getStorageItem("currencyCode")) {
      this.setState({
        currencyCode: getStorageItem("currencyCode"),
      });
    }
    if (getStorageItem("user")) {
      this.setState({ pointsDynamicName: getStorageItem("pointShortName") });
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("product_merchant_id", productId);
      bodyFormData.append("product_id", 0);
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      bodyFormData.append("platform", apiData.platform);
      await this.props.getProductDetails(bodyFormData, productId);

      const trackFormData = new URLSearchParams();

      trackFormData.append("product_merchant_id", productId);
      trackFormData.append("merchant_id", apiData.merchant_id);
      trackFormData.append("customer_id", getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
      await this.props.TrackProductView(trackFormData);
      showLoader(false);
    } else {
      this.props.logoutUser();
    }
    this.setState({ selectedLocation: this.props.location.state.details.locations })
  };

  getPrice = (specialPrice, startDate, endDate, sellingPrice) => {
    const moment = require("moment");
    const currentDate = moment().format("YYYY-MM-DD");
    const { classes } = this.props;
    if (specialPrice === "0.0000") {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else if (endDate && endDate === currentDate) {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
        },
        {
          classKey: classes.sellingPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else if (startDate && startDate === currentDate) {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
        },
        {
          classKey: classes.sellingPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else if (moment(currentDate).isBetween(startDate, endDate)) {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
        },
        {
          classKey: classes.sellingPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    }
  };

  handleOnChange = async (e, pro_data) => {
    const { onVoucherChange, finalCartArray, selectedProductDetails } =
      this.props;
    const selectedIndex = e.target.options.selectedIndex;
    const id = e.target.options[selectedIndex].getAttribute("id");
    const pro_id = e.target.options[selectedIndex].getAttribute("prod_id");
    let temp_quantity;
    finalCartArray &&
      finalCartArray.length > 0 &&
      finalCartArray.forEach((item) => {
        if (item.product_merchant_id === id) {
          temp_quantity = item.qty;
        }
      });

    this.setState({
      selectedVoucherValue: e.target.value,
      currentQuantity: 0,
      selectedQuantity: temp_quantity ? temp_quantity : 1,
      selectedLocation:
        selectedProductDetails &&
        selectedProductDetails[0] &&
        selectedProductDetails[0].location_name,
      selectedLocation_id: null,
    });
    onVoucherChange(id, pro_data);
    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("product_merchant_id", id);
      bodyFormData.append("product_id", pro_id);
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append(
        "merchant_id",
        // this.props.history.location.state &&
        //   this.props.history.location.state.details &&
        //   this.props.history.location.state.details.merchant_id
        apiData.merchant_id
      );
      bodyFormData.append("platform", apiData.platform);
      await this.props.getProductDetails(bodyFormData, id);

      const trackFormData = new URLSearchParams();
      trackFormData.append("product_merchant_id", id);
      trackFormData.append("merchant_id", apiData.merchant_id);
      trackFormData.append("customer_id", getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
      await this.props.TrackProductView(trackFormData);
      this.props.showLoader(false);
    } else {
      this.props.logoutUser();
    }
  };

  handleOnLocationChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const id = e.target.options[selectedIndex].getAttribute("id");
    this.setState({
      selectedLocation: e.target.value,
      selectedLocation_id: id,
    });
  };
  componentWillUnmount() {
    const { clearProductDetails, clearSelectedProductDetails } = this.props;
    clearProductDetails();
    clearSelectedProductDetails();
    this.setState({
      selectedQuantity: null,
      selectedLocation_id: null,
      selectedLocation: "",
    });
  }

  onclickTermAndContion = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  render() {
    const { classes, selectedProductDetails, productDetails } = this.props;
    const {
      currentQuantity,
      selectedQuantity,
      selectedLocation,
      pointsDynamicName,
      currencyCode,
    } = this.state;
    const finalCartArray = [...this.props.finalCartArray];
    let temp_quantity;
    let temp_location;
    finalCartArray &&
      finalCartArray.length > 0 &&
      finalCartArray.forEach((item) => {
        let productID =
          selectedProductDetails &&
          selectedProductDetails[0] &&
          selectedProductDetails[0].id;
        if (item.product_merchant_id === productID) {
          temp_quantity = item.qty;
          temp_location = item.location_name;
        }
      });
    const maxQty =
      selectedProductDetails &&
      selectedProductDetails[0] &&
      selectedProductDetails[0].max_quantity;

    let productID =
      selectedProductDetails &&
      selectedProductDetails[0] &&
      selectedProductDetails[0].id;
    let disableBtn;
    const getStatus = () => {
      finalCartArray &&
        finalCartArray.length > 0 &&
        finalCartArray.forEach((x) => {
          if (x.product_merchant_id === productID) {
            if (x.qty == maxQty) {
              disableBtn = true;
            } else {
              disableBtn = false;
            }
          }
        });
    };

    const isEnabled = maxQty == currentQuantity;
    const termsConditionContent =
      selectedProductDetails &&
      selectedProductDetails[0] &&
      selectedProductDetails[0].short_description;

    return (
      <div className={classes.mainContainer}>
        <Grid container className={classes.gridContainer}>
          {getStatus()}
          <div className={classes.reedemDetailscnt}>
            <Carousal
              imagesArray={
                selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].product_images.length > 0
                  ? selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].product_images
                  : [default_product]
              }
              alt={
                selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].sub_product_name.length > 0
                  ? selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].sub_product_name
                  : ["No_image_available"]
              }
            />
            {selectedProductDetails &&
              selectedProductDetails[0] &&
              selectedProductDetails[0].display_custom_text_or_date &&
              selectedProductDetails[0].display_custom_text_or_date === "1" &&
              selectedProductDetails[0].expiry_date &&
              selectedProductDetails[0].expiry_date && (
                <p className={classes.txExpires}>
                  {this.convertDate(selectedProductDetails[0].expiry_date) ===
                    "Invalid date" ? (
                    ""
                  ) : (
                    <Fragment>
                      Expires{" "}
                      {this.convertDate(selectedProductDetails[0].expiry_date)}
                    </Fragment>
                  )}
                </p>
              )}

            {selectedProductDetails &&
              selectedProductDetails[0] &&
              selectedProductDetails[0].display_custom_text_or_date &&
              selectedProductDetails[0].display_custom_text_or_date === "2" &&
              selectedProductDetails &&
              selectedProductDetails[0] &&
              selectedProductDetails[0].custom_text && (
                <p className={classes.txExpires}>
                  {selectedProductDetails[0].custom_text}
                </p>
              )}

            <div className={classes.reedemCntwrapper}>
              <div className={classes.brandName}>
                {selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].brand_name}
              </div>
              <div className={classes.productName}>
                {selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].sub_product_name}
              </div>

              <div className={classes.priceRow}>
                {this.getPrice(
                  selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].special_price_points_value,
                  selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].special_price_start_date,
                  selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].special_price_end_date,
                  selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].selling_price_points_value
                ).map((item, index) => {
                  return (
                    <span key={index} className={item.classKey}>
                      {!isNaN(item.value) ? item.value : "0"}{" "}
                      {pointsDynamicName ? pointsDynamicName : "P"}
                    </span>
                  );
                })}
              </div>
              <div className={classes.formBlock}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} className={classes.smPaddeing}>
                    <Paper className={classes.paper}>
                      <InputLabel className={classes.labelSelect}>
                        Choose Quantity
                      </InputLabel>
                      <FormControl className={classes.formControl}>
                        <Select
                          native
                          IconComponent={() => (
                            <img
                              className={classes.down_icon}
                              src={down_arrow}
                              alt=""
                              width="10"
                              height="6"
                            />
                          )}
                          disabled={isEnabled || disableBtn}
                          value={
                            selectedQuantity ? selectedQuantity : temp_quantity
                          }
                          onChange={(e) =>
                            this.setState({
                              selectedQuantity: parseInt(e.target.value),
                            })
                          }
                        >
                          {this.quantityOptions()}
                        </Select>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    className={classes.smPaddeing}
                  >
                    <Paper className={classes.paper}>
                      <InputLabel className={classes.labelSelect}>
                        Voucher Value
                      </InputLabel>
                      <FormControl className={classes.formControl}>
                        <Select
                          native
                          IconComponent={() => (
                            <img
                              className={classes.down_icon}
                              src={down_arrow}
                              alt=""
                              width="10"
                              height="6"
                            />
                          )}
                          value={this.state.selectedVoucherValue}
                          onChange={(e) =>
                            this.handleOnChange(
                              e,
                              productDetails &&
                              productDetails.productDetails &&
                              productDetails.productDetails
                            )
                          }
                        >
                          {productDetails &&
                            productDetails.productDetails &&
                            productDetails.productDetails.map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  name={item.id}
                                  id={item.id}
                                  prod_id={item.product_id}
                                  value={this.roundOff(item.voucher_value)}
                                  defaultValue={
                                    item.id ===
                                    (selectedProductDetails &&
                                      selectedProductDetails[0] &&
                                      selectedProductDetails[0].id)
                                  }
                                >
                                  {currencyCode}{" "}
                                  {this.roundOff(item.voucher_value)}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Paper className={classes.paper}>
                      <InputLabel className={classes.labelSelect}>
                        Reward Location
                      </InputLabel>
                      <FormControl className={classes.formControl}>
                        <Select
                          native
                          IconComponent={() => (
                            <img
                              className={classes.down_icon}
                              src={down_arrow}
                              alt=""
                              width="10"
                              height="6"
                            />
                          )}
                          value={selectedLocation || temp_location}
                          onChange={(e) => {
                            this.handleOnLocationChange(
                              e,
                              productDetails &&
                              productDetails.productDetails &&
                              productDetails.productDetails
                            );
                          }}
                        >
                          {productDetails &&
                            productDetails.locationWiseQuantity &&
                            productDetails.locationWiseQuantity.map((item) => (
                              <option
                                className={classes.optionSelect}
                                key={item.location_id}
                                id={item.location_id}
                                merchant_id={item.products_merchant_id}
                                value={item.location_name}
                              // disabled={item.current_quantity === "0"}
                              >
                                {item.location_name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.clearBoth}></div>
              <div
                className={classes.checkBoxContainer}
              // style={{ zIndex: !this.state.checked ? "10" : "0" }}
              >
                <div className={classes.checkbxContent}>
                  <input
                    className={classes.checkBx}
                    type="checkbox"
                    defaultChecked={this.state.checked}
                    onChange={this.handleChange}
                  />
                </div>

                <div>
                  <p className={classes.checkBoxText}>
                    I accept all the{" "}
                    <span
                      onClick={this.onclickTermAndContion}
                      className={classes.termsCondText}
                    >
                      terms &amp; conditions.
                  </span>
                  </p>
                </div>
              </div>
              <div className={classes.descriptionWrapper}>
                <span>
                  <strong>Description:</strong>
                </span>
                {/* <p className={classes.descriptionClass}>
                  {selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0].short_description
                    ? selectedProductDetails &&
                      selectedProductDetails[0] &&
                      selectedProductDetails[0].short_description
                    : "NA"}
                </p> */}

                <div
                  className={classes.descriptionClass}
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedProductDetails &&
                        selectedProductDetails[0] &&
                        selectedProductDetails[0].short_description
                        ? selectedProductDetails &&
                        selectedProductDetails[0] &&
                        selectedProductDetails[0].short_description
                        : " <div style='text-align:center;'>No data available</div>",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Grid>

        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            className={classes.buttonAdd}
            disabled={isEnabled || disableBtn || !this.state.checked}
            onClick={() =>
              this.addProductToCart({
                key: "Add",
                selected:
                  selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0],
              })
            }
          >
            Add to cart
          </Button>
          <Button
            variant="contained"
            className={classes.buttonBuy}
            disabled={isEnabled || disableBtn || !this.state.checked}
            onClick={() =>
              this.addProductToCart({
                key: "buyNow",
                selected:
                  selectedProductDetails &&
                  selectedProductDetails[0] &&
                  selectedProductDetails[0],
              })
            }
          >
            Buy Now
          </Button>
        </div>

        <div>
          <Dialog
            maxWidth="md"
            // className={classes.dialogModal}
            open={this.state.isModalVisible}
          >
            {/* <DialogTitle className={classes.dialogTitle}></DialogTitle> */}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedProductDetails &&
                        selectedProductDetails[0] &&
                        selectedProductDetails[0].short_description
                        ? selectedProductDetails &&
                        selectedProductDetails[0] &&
                        selectedProductDetails[0].short_description
                        : " <div style='text-align:center;'>No data available</div>",
                  }}
                ></p>
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                className={classes.okBtn}
                color="primary"
                className={classes.okBtn}
                onClick={() => {
                  this.setState({ isModalVisible: !this.state.isModalVisible });
                }}
                color="primary"
              >
                {" "}
                OK{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ProductDetails)
);
