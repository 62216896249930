import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { withStyles } from "@material-ui/styles";
import { styles } from "./OffersComponentStyle";
import OffersCard from "../../../../components/OffersCard/OffersCard";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";
import Search from "../../../../components/Search/Search";
import { setStorageItem } from "../../../../common/localStorage";
import InfiniteScroll from "react-infinite-scroller";

class OffersComponent extends Component {
  state = {
    showDetails: false,
    details: {},
    pointsDynamicName: "",
  };

  convertDate = (expiryDate) => {
    const moment = require("moment");
    const today = moment(expiryDate);
    return today.format("Do MMM'YY");
  };

  componentWillUnmount() {
    const { clearOffers, resetOffersSearchText } = this.props;
    const { offer_id } = this.state;
    // clearOffers();
    if (this.props.history.location.pathname !== `/offers-detail/${offer_id}`) {
      // resetOffersSearchText();
    }
  }
  render() {
    const { classes, offersList, searchActive, hasMore, loader } = this.props;

    return (
      <div
        className={classnames(classes.redeemCnt, {
          // [classes.common_padding]: searchActive,
        })}
        style={{ margin: "0px" }}
      >
        {searchActive && (
          <div className={classes.searchBox_padding}>
            <Search
              onSearchTextChange={this.props.onSearchTextChange}
              searchText={this.props.searchOffer}
              // className="p15"
              paddingBottom="pB0"
              searchIcon={true}
              autoFocus={true}
              placeholder="Search Offers"
              resetSearch={this.props.resetSearch}
              searchCall={this.props.searchCall}
            />
          </div>
        )}
        <div
          className={classnames({
            [classes.common_padding]: !searchActive,
            [classes.common_padding2]: searchActive,
            [classes.infiniteScroll]: !searchActive,
            [classes.searchActive]: searchActive,
          })}
        >
          <InfiniteScroll
            loadMore={() => {
              if (offersList.length > 0) {
                this.props.getOffer(true);
              }
            }}
            hasMore={hasMore}
            useWindow={true}
          >
            {offersList.map((offer) => (
              <OffersCard
                key={offer.id}
                id={offer.id}
                offer={offer}
                viewOfferDetails={this.props.viewOfferDetails}
                convertDate={this.convertDate}
                merchant_name={this.props.merchant_name}
              />
            ))}
          </InfiniteScroll>
          {this.props.noRecords && this.props.datarcv ? (
            <NoRecordFound message={"No perks found"} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(OffersComponent)
);

OffersComponent.defaultProps = {
  offersList: [],
  pageNum: 1,
  noRecords: false,
};
